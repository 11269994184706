<template>
  <!--  <Writing2 ref="writing"></Writing2>-->
  <router-view></router-view>
  <!--   <Writing style="margin:100px"></Writing>-->
  <!--  <div style="padding:100px">
      <Writing2 ref="writing"></Writing2>
      <button
          @click="getOutputData"
          class="block m-1 font-normal bg-primary text-white pl-10 pr-10 pt-2
                                 pb-2 rounded-md transition duration-500 ease-in-out transform hover:scale-110">
        Lấy
      </button>
    </div>-->
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
//import Writing2 from "./components/marking/Writing2";
import { mapGetters } from "vuex";
// import Writing from '@/components/marking/Writing.vue';
//import Writing3 from "./components/marking/Writing3";

export default {
  name: "MetronicVue",
  components: {
    /* Writing2*/
    /* Writing3*/
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Prep Management";
      },
    },
  },
  methods: {
    /* getOutputData() {
       console.log(this.$refs.writing.getOutputData());
     }*/
  },
};
</script>
